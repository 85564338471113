import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  activeUsers:string[] =
  [
    'A',
    'B'
  ];
  inactiveUsers:string[] =[
    'C',
    'D'
  ];
  onSetToActive(id:number)
  {
    this.activeUsers.push(this.inactiveUsers[id]);
    this.inactiveUsers.splice(id,1);
    
    console.log("Set active:"+id);
  }
  onSetToInactive(id:number)
  {
    this.inactiveUsers.push(this.activeUsers[id]);
    this.activeUsers.splice(id,1);
    console.log("Set inactive:"+id);
  }
}
