import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.css']
})
export class ActiveUsersComponent implements OnInit {

  @Input() users: string[];
  @Output() userSetToInactive = new EventEmitter<number>();

  SetInactive(i:number)
  {
    this.userSetToInactive.emit(i);
  }

  constructor() { }

  ngOnInit() {
  }

}
